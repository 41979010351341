import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import '../components/all.css';
import { GlobalRootStyle } from '../components/global-style';
import styled from 'styled-components';
import MarkdownContent from '../components/Markdown';
import HubspotForm from 'react-hubspot-form';
import Logo from "../img/logo-white.svg"
const Container = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${props => props.hero});
  background-position: center;
  background-size: cover;
  color: white;
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align:center;
  ul{
    list-style:none;
  }
  .logo{
    width: 15rem;
  }
  .form {
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.7));
    padding: 2rem;
  }
`;

export const LandingPageTemplate = ({ title, hero, html, formId, portalId }) => {
  return (
    <>
      <GlobalRootStyle />

      <Container hero={hero}>
        <img src={Logo} className="logo"/>
        <h1>{title}</h1>
        <MarkdownContent content={html} />
        <div className="form">
          <HubspotForm
            portalId={portalId}
            formId={formId}
            onSubmit={() => console.log('Submit!')}
            onReady={form => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        </div>
      </Container>
    </>
  );
};

const LandingPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { title, portalId, formId, hero } = frontmatter;
  return (
    <>
      <LandingPageTemplate
        hero={hero.childImageSharp.fluid.src}
        html={html}
        title={title}
        portalId={portalId}
        formId={formId}
      />
    </>
  );
};

LandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export const carreireQuery = graphql`
  query Landingquery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        portalId
        formId
        hero {
          childImageSharp {
            fluid(maxWidth: 1700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default LandingPage;
